import {
	ActionReducerMapBuilder,
	createAsyncThunk,
	createSlice,
	PayloadAction
} from '@reduxjs/toolkit'
import {
	IRequestConfigsState,
	IRequestLocationState,
	IRequestModelState,
	IRequestState,
	IRequestCreatedState
} from '@services/store/request/type'
import { IThunk } from '@services/store/type'
import { IUser } from '@services/types'
import {
	Address,
	Request,
	AddressCoordinates,
	RequestConfigs,
	User,
	RequestNumericTransaction
} from '@services/models'
import { requestCategoryDic, requestSubCategoryDic } from '@services/constants'
import { RequestInputFiles, TNRequestNames } from '@utils/request'
import { setRequestListStore } from '../requestList'
import store from '@services/store'

const initialState: IRequestState = {
	requestCreated: {
		code: '',
		id: ''
	},
	requestFailed: false,
	model: {
		title: '',
		typeId: '',
		name: '',
		description: '',
		customerId: '',
		metadata: [],
		files: []
	},
	location: {
		useDefaultUserLocation: true,
		city: '',
		address: '',
		apartment: '',
		postalCode: '',
		coordinates: {}
	},
	configs: {} as IRequestConfigsState,
	listRequestIdsForArticles: []
}

export const slice = createSlice({
	name: 'createRequest',
	initialState,
	reducers: {
		populateRequestModelState: (
			state,
			action: PayloadAction<Partial<IRequestModelState>>
		) => {
			Object.assign(state.model, action.payload)
		},
		populateRequestConfigsState: (
			state,
			action: PayloadAction<Partial<IRequestConfigsState>>
		) => {
			Object.assign(state.configs, action.payload)
		},
		setRequestConfigsState: (state, action) => {
			state.configs = action.payload
		},
		populateRequestLocationState: (
			state,
			action: PayloadAction<Partial<IRequestLocationState>>
		) => {
			Object.assign(state.location, action.payload)
		},
		clearRequestState: (state) => {
			state.requestCreated = initialState.requestCreated
			state.requestFailed = initialState.requestFailed
			state.configs = initialState.configs
			state.location = initialState.location
			state.model = initialState.model
			state.listRequestIdsForArticles = initialState.listRequestIdsForArticles
		},
		resetRequestConfigsState: (state) => {
			state.configs = initialState.configs
		},
		populateArticlesRequestId: (state, action: PayloadAction<string[]>) => {
			state.listRequestIdsForArticles = action.payload
		},
		resetRequestFailedState: (state, action: PayloadAction<boolean>) => {
			state.requestFailed = action.payload
		}
	},
	extraReducers: (builder: ActionReducerMapBuilder<IRequestState>) => {
		builder
			.addCase(createRequest.fulfilled, (state, { payload }) => {
				state.requestCreated = payload
			})
			.addCase(createRequest.rejected, (state) => {
				state.requestFailed = true
			})
	}
})

export const createRequest = createAsyncThunk<
	IRequestCreatedState,
	User,
	IThunk
>('request/create', async (authUser, thunkAPI) => {
	const state = thunkAPI.getState()

	const request: Request = new Request(authUser as IUser)
	const location: Address = new Address()
	const coordinates: AddressCoordinates = new AddressCoordinates()
	const configs: RequestConfigs = new RequestConfigs()
	// const dispatch = useAppDispatch()

	coordinates.update(state.request.location.coordinates)

	if (!coordinates.validate()) {
		throw new Error('coordinates can not be empty')
	}

	location.update({
		...state.request.location,
		coordinates
	})

	configs.update(state.request.configs)

	request.update({
		...state.request.model,
		configs,
		location
	})

	await request.saveAsync()

	await request.saveAttachmentAsync()

	const requestsList = await authUser.fetchRequests()

	store.dispatch(
		setRequestListStore({
			fetching: false,
			fetched: true,
			requests: requestsList
		})
	)

	const requestFound: Request | undefined = authUser.getRequest(`${request.id}`)

	return {
		id: `${request.id}`,
		code: `${requestFound?.code}`
	}
})

export const createTNRequest = createAsyncThunk<
	IRequestCreatedState,
	{ authUser: User; filesInputs: RequestInputFiles[] },
	IThunk
>('request/create', async ({ authUser, filesInputs }, thunkAPI) => {
	const state = thunkAPI.getState()

	const request: Request = new Request(authUser as IUser)
	const location: Address = new Address()
	const coordinates: AddressCoordinates = new AddressCoordinates()
	const configs: RequestConfigs = new RequestConfigs()
	// const dispatch = useAppDispatch()

	coordinates.update(state.request.location.coordinates)

	location.update({
		...state.request.location,
		coordinates
	})

	configs.update(state.request.configs)

	request.update({
		...state.request.model,
		configs,
		location
	})

	if (
		// les requêtes autorisées à soumission avec adresse en dehors de Laval
		![
			TNRequestNames.annualRegistrationCertificateForContractors,
			TNRequestNames.historicalArchive,
			TNRequestNames.hydraulicAndPressureTests,
			TNRequestNames.publicAuctions,
			TNRequestNames.fireHydrantsTankFilling,
			TNRequestNames.fireHydrantsTempAqueductNetwork,
			TNRequestNames.temporaryPesticideApplicationPermitForIndividuals,
			TNRequestNames.permitOccupationOfPublicHighway,
			TNRequestNames.claimNotice,
			TNRequestNames.municipalEvaluation,
			TNRequestNames.carSharing,
			TNRequestNames.replacementOilHeatingSystem,
			TNRequestNames.treePlanting,
			TNRequestNames.sustainableHygieneProducts,
			TNRequestNames.bikeSharing,
			TNRequestNames.buildingTransactionRequest,
			TNRequestNames.rainwaterBarrel,
			TNRequestNames.crackedHouses,
			TNRequestNames.heritageBuildingsRevitalizationProgram,
			TNRequestNames.fireplaceDeclaration,
			TNRequestNames.selfReliefWaterCounter,
			TNRequestNames.oilHeatingDeclaration,
			TNRequestNames.fireplaceGrant,
			TNRequestNames.parkingPermit,
			TNRequestNames.swimmingPoolSpaPermit,
			TNRequestNames.buildingConstructionOrAdditionPermit,
			TNRequestNames.wateringPermit,
			TNRequestNames.buildingEnlargementPermit
		].includes(String(request.name)) &&
		!coordinates.validate()
	) {
		console.error('coordinates can not be empty')
		throw new Error('coordinates can not be empty')
	}

	await request.saveAsyncTN()

	for (let input of filesInputs) {
		if (!!input.separateFiles) {
			const keys = Object.keys(input.separateFiles!)
			for (let key of keys) {
				const NT = new RequestNumericTransaction({
					incidentId: '',
					incidentFormId: '',
					category: requestCategoryDic[input.category || input.name],
					sub_category: requestSubCategoryDic[input.subCategory || input.name],
					description: input.description || key,
					subject: key
				})

				NT.incidentId = request.id!
				NT.incidentFormId = request.formId!
				NT.file = [...input.separateFiles![key]][0]

				await NT.saveAsyncNumericTransaction()

				await NT.saveTNAttachment()
			}
		} else if (input.files.size > 0 || input.files.length > 0) {
			for (let file of input.files) {
				const NT = new RequestNumericTransaction({
					incidentId: '',
					incidentFormId: '',
					category: requestCategoryDic[input.category || input.name],
					sub_category: requestSubCategoryDic[input.subCategory || input.name],
					description: input.description || input.label,
					subject: input.label || ' '
				})

				NT.incidentId = request.id!
				NT.incidentFormId = request.formId!
				NT.file = file

				await NT.saveAsyncNumericTransaction()

				await NT.saveTNAttachment()
			}
		}
	}

	const requestsList = await authUser.fetchRequests()

	store.dispatch(
		setRequestListStore({
			fetching: false,
			fetched: true,
			requests: requestsList
		})
	)

	const requestFound: Request | undefined = authUser.getRequest(`${request.id}`)

	return {
		id: `${request.id}`,
		code: `${requestFound?.code}`
	}
})

export default slice.reducer

export const {
	populateRequestModelState,
	populateRequestConfigsState,
	setRequestConfigsState,
	populateRequestLocationState,
	clearRequestState,
	resetRequestConfigsState,
	populateArticlesRequestId,
	resetRequestFailedState
} = slice.actions
